import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CodeInputPage = () => {
  const [code, setCode] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setCode(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (code === 'risbo') {
      navigate('/home');
    } else {
      alert('Code incorrect');
    }
  };

  return (
    <div style={{ backgroundColor: '#000', color: '#fff', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <form onSubmit={handleSubmit}>
        <label>
          Entrez le code :
          <input type="text" value={code} onChange={handleInputChange} />
        </label>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default CodeInputPage;
