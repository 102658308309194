// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UnderConstruction from './UnderConstruction';
import Home from './Home';
import History from './HistoryPage';
import CodeInputPage from './CodeInputPage';
import './styles.css'; // Importer le fichier CSS global

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<CodeInputPage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/hist" element={<History />} />
      </Routes>
    </Router>
  );
};


export default App;
