import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import config from '../config';  // Importez la configuration

const ImageSection = () => {
  const [detectionData, setDetectionData] = useState({});

  const fetchSavedDetectionData = async () => {
    try {
      const response = await axios.get(`${config.apiServer}/GetSavedDetectionInfo`);
      const data = response.data;

      const latestData = [0, 1, 2, 3].reduce((acc, camId) => {
        const camData = data[`CAMERA_${camId}`];
        if (camData && camData.length > 0) {
          // Sort the camData by detection_date to find the most recent one
          const sortedData = camData.sort((a, b) => new Date(b.detection_date) - new Date(a.detection_date));
          const lastDetection = sortedData[0];
          acc[`cam${camId}`] = lastDetection;
        } else {
          acc[`cam${camId}`] = null; // Handle case where no detection data is found
        }
        return acc;
      }, {});

      setDetectionData(latestData);
    } catch (error) {
      console.error('Error fetching saved detection data:', error);
    }
  };

  useEffect(() => {
    fetchSavedDetectionData();
    const intervalId = setInterval(fetchSavedDetectionData, 2000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Grid container spacing={2}>
      {[0, 1, 2, 3].map((camId) => (
        <Grid item xs={3} key={camId}>
          {detectionData[`cam${camId}`] ? (
            <>
              <img
                src={`${config.apiServer}/GetSavedImage?FilePath=${encodeURIComponent(detectionData[`cam${camId}`].image_path)}`}
                alt={`Detection for cam ${camId}`}
                style={{ width: '100%' }}
              />
              <Typography variant="body2" align="center">
                {detectionData[`cam${camId}`].detection_date}
              </Typography>
            </>
          ) : (
            <Typography variant="body2" align="center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', color: 'red' }}>
              No detection
            </Typography>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default ImageSection;
