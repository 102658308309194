import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Scrollbars from 'react-scrollbars-custom';
import ReactPlayer from 'react-player';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import './styles.css';
import config from './config';

const HistoryPage = () => {
  const [historyData, setHistoryData] = useState(null); // Nouvelles données
  const [cachedHistoryData, setCachedHistoryData] = useState(null); // Données en cache
  const [selectedCamera, setSelectedCamera] = useState(0);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const playerContainerRef = useRef(null);

  useEffect(() => {
    fetchHistoryData();
    const intervalId = setInterval(fetchHistoryData, 2000); // Appel de l'API toutes les 2 secondes

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      clearInterval(intervalId); // Nettoyage de l'intervalle lors du démontage du composant
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchHistoryData = async () => {
    try {
      const response = await axios.get(`${config.apiServer}/GetSavedDetectionInfo`);
      const newData = response.data;

      // Comparaison avec les données en cache
      if (JSON.stringify(newData) !== JSON.stringify(cachedHistoryData)) {
        setHistoryData(newData);
        setCachedHistoryData(newData); // Mise à jour des données en cache
      }
    } catch (error) {
      console.error('Error fetching history data:', error);
    }
  };

  const handleImageClick = (imagePath, videoPath) => {
    const videoUrl = `${config.apiServer}/GetSavedVideo?FilePath=${encodeURIComponent(videoPath)}`;
    setSelectedVideo(videoUrl);
  };

  const handleCleanCamera = async (cameraID) => {
    try {
      const response = await axios.get(`${config.apiServer}/CleanSavedCam?cameraID=${cameraID}`);
      console.log(response.data.message);
      fetchHistoryData();
    } catch (error) {
      console.error('Error cleaning camera folder:', error);
    }
  };

  const handleClickOutside = (event) => {
    if (playerContainerRef.current && !playerContainerRef.current.contains(event.target)) {
      setSelectedVideo(null);
    }
  };

  const handleCameraChange = (event) => {
    setSelectedCamera(event.target.value);
  };

  const handleGoToHome = () => {
    window.location.href = '/home'; // Redirection vers la route /home
  };

  return (
    <div style={{ backgroundColor: '#000', color: '#fff', padding: '20px', position: 'relative', textAlign: 'center', minHeight: '100vh', overflow: 'hidden' }}>
      {/* Bouton pour retourner à /home */}
      <Button variant="contained" color="primary" onClick={handleGoToHome} style={{ position: 'absolute', top: '20px', right: '20px' }}>
        Retour à Home
      </Button>

      {/* Titre et Sélection de la caméra */}
      <Typography variant="h6" gutterBottom style={{ color: '#fff', marginBottom: '10px' }}>
        Camera {selectedCamera}
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12}>
          <Select value={selectedCamera} onChange={handleCameraChange} style={{ backgroundColor: '#2196f3', color: '#fff', marginBottom: '20px', width: '200px' }}>
            {[0, 1, 2, 3].map((cameraIndex) => (
              <MenuItem key={cameraIndex} value={cameraIndex}>
                Camera {cameraIndex}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <button onClick={() => handleCleanCamera(selectedCamera)} className="erase-button">
            Erase CAM_{selectedCamera}
          </button>
        </Grid>
        <Grid item xs={12} style={{
          width: '80%', // Par défaut, utilise 80% de la largeur
          maxWidth: '800px', // Limite la largeur maximale à 800px
          margin: '0 auto', // Centre horizontalement
          marginBottom: '20px', // Espace en bas
        }}>
          <Scrollbars
            style={{ width: '100%', height: '600px' }} // Ajuste la hauteur selon vos besoins
            className="custom-scrollbar"
            renderer={(props) => {
              const { elementRef, ...restProps } = props;
              return <div {...restProps} ref={elementRef} style={{ ...props.style, overflowX: 'hidden' }} />;
            }}
            trackYProps={{
              renderer: (props) => {
                const { elementRef, ...restProps } = props;
                return (
                  <div
                    {...restProps}
                    ref={elementRef}
                    style={{
                      ...props.style,
                      backgroundColor: '#fff',
                      borderRadius: '3px',
                      zIndex: '1000',
                    }}
                  />
                );
              },
            }}
            trackXProps={{
              style: { display: 'none' },
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column-reverse', gap: '10px' }}>
              {historyData &&
                historyData[`CAMERA_${selectedCamera}`]?.map((item, index) => (
                  <div
                    key={index}
                    style={{ cursor: 'pointer', border: '1px solid blue', padding: '10px' }}
                    onClick={() => handleImageClick(item.image_path, item.video_path)}
                  >
                    <img
                      src={`${config.apiServer}/GetSavedImageLight?FilePath=${encodeURIComponent(item.image_path)}`}
                      alt={`Detection ${index}`}
                      style={{ width: '100%', marginBottom: '10px' }}
                    />
                    <Typography variant="body2">{item.detection_date}</Typography>
                  </div>
                )).reverse()}
            </div>
          </Scrollbars>
        </Grid>
      </Grid>

      {/* Affichage du lecteur vidéo s'il y a une vidéo sélectionnée */}
      {selectedVideo && (
        <div
          ref={playerContainerRef}
          style={{
            position: 'fixed',
            top: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: '2000',
            backgroundColor: '#000',
            padding: '10px',
            borderRadius: '8px',
            width: '80vw', // Ajuste la largeur du lecteur vidéo
            maxWidth: '800px', // Limite la largeur maximale pour les grands écrans
          }}
        >
          <ReactPlayer url={selectedVideo} controls width="100%" height="100%" />
        </div>
      )}
    </div>
  );
};

export default HistoryPage;
