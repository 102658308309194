import React, { useState } from 'react';
import axios from 'axios';
import { FormControlLabel, Checkbox } from '@mui/material';
import config from '../config';  // Importez la configuration
import '../styles.css'; // Importer le fichier CSS global

const CheckboxList = () => {
  const [checkedState, setCheckedState] = useState({
    mails: false,
    notifications: false,
    light: false,
    saving: true,
  });

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setCheckedState({
      ...checkedState,
      [name]: checked,
    });
    let url = '';
    switch (name) {
      case 'mails':
        url = `${config.apiServer}/ActivateMails?ALLOW=${checked ? 1 : 0}`;
        break;
      case 'notifications':
        url = `${config.apiServer}/ActivateNotifications?ALLOW=${checked ? 1 : 0}`;
        break;
      case 'light':
        url = `${config.apiServer}/ActivateLightControl?ALLOW=${checked ? 1 : 0}`;
        break;
      case 'saving':
        url = `${config.apiServer}/ActivateAll?ALLOW=${checked ? 1 : 0}`;
        break;
      default:
        break;
    }
    axios.get(url);
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedState.mails}
            onChange={handleChange}
            name="mails"
            className="custom-checkbox" // Applique la classe 'custom-checkbox'
          />
        }
        label="Activate Mails notif"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedState.notifications}
            onChange={handleChange}
            name="notifications"
            className="custom-checkbox" // Applique la classe 'custom-checkbox'
          />
        }
        label="Activate Notifications"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedState.light}
            onChange={handleChange}
            name="light"
            className="custom-checkbox" // Applique la classe 'custom-checkbox'
          />
        }
        label="Activate Light"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedState.saving}
            onChange={handleChange}
            name="saving"
            className="custom-checkbox" // Applique la classe 'custom-checkbox'
          />
        }
        label="Activate Saving"
      />
    </div>
  );
};

export default CheckboxList;
