import React from 'react';
import { Container, Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import VideoStream from './components/VideoStream';
import CheckboxList from './components/CheckboxList';
import ImageSection from './components/ImageSection';

const Home = () => {
  const navigate = useNavigate();

  const handleNavigateToHist = () => {
    navigate('/hist');
  };

  return (
    <div style={{ backgroundColor: '#000', color: '#fff', width: '100%', height: '100%', overflowY: 'scroll', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {/* Container pour contenir les éléments */}
      <Container style={{ padding: '5px', textAlign: 'center', maxWidth: '95vw', width: '80vw' }}>
        {/* Bouton pour naviguer vers /hist */}
        <Button
          style={{ position: 'absolute', top: '10px', right: '10px', backgroundColor: '#2196f3', color: '#fff', marginBottom: '10px' }}
          onClick={handleNavigateToHist}
          variant="contained"
        >
          Go to Hist
        </Button>

        <Grid container spacing={1} style={{ flex: '1', overflowY: 'auto' }}>
          <Grid item xs={12}>
            <VideoStream RefreshPeriodMs={25} />
          </Grid>
          <Grid item xs={12}>
            <CheckboxList />
          </Grid>
          <Grid item xs={12}>
            <ImageSection />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Home;
