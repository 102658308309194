import React, { useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import config from '../config';  // Importez la configuration

const VideoStream = ({ RefreshPeriodMs }) => {
  const videoRefs = useRef([React.createRef(), React.createRef(), React.createRef(), React.createRef()]);

  useEffect(() => {
    const intervalIds = videoRefs.current.map((ref, index) => {
      return setInterval(() => {
        const timestamp = Date.now(); // Obtient un timestamp précis en millisecondes
        const imageUrl = `${config.apiServer}/GetCam?CAM_ID=${index}&_t=${timestamp}`;
        
        // Crée une nouvelle image pour éviter les problèmes de cache
        const img = new Image();
        img.onload = () => {
          if (ref.current) {
            ref.current.src = img.src;
          }
        };
        img.src = imageUrl;
      }, RefreshPeriodMs);
    });

    return () => {
      intervalIds.forEach(clearInterval);
    };
  }, [RefreshPeriodMs]);

  return (
    <Grid container spacing={2}>
      {videoRefs.current.map((ref, index) => (
        <Grid item xs={6} key={index}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              ref={ref}
              src={`${config.apiServer}/GetCam?CAM_ID=${index}`} // Image initiale sans timestamp
              alt={`video stream ${index}`}
              style={{ width: '100%', height: 'auto' }}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default VideoStream;
